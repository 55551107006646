import { Component, OnInit } from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { ManagerAuthService } from './services/managers/manager-auth/manager-auth.service';
import { ENVIRONMENT } from '../../../../settings/environments/environment.development';
import { Auth, connectAuthEmulator } from '@angular/fire/auth';
import { Firestore, connectFirestoreEmulator } from '@angular/fire/firestore';
import { Storage, connectStorageEmulator } from '@angular/fire/storage';
import { ModalAccountCreateComponent } from './modals/modal-account-create/modal-account-create.component';
import { ButtonCloseOffcanvasComponent } from "./components/button-close-offcanvas/button-close-offcanvas.component";
import { ManagerAccountService } from './services/managers/manager-account/manager-account.service';
import { Account } from './interfaces/account';
import { ManagerChatService } from './services/managers/manager-chat/manager-chat.service';
import { UtilNotificationService } from './services/utils/util-notification/util-notification.service';
import { UtilUpdateService } from './services/utils/util-update/util-update.service';
import { Gifcome } from './interfaces/gifcome';
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { StateProcessingService } from './services/states/state-processing/state-processing.service';
import { ManagerCounterService } from './services/managers/manager-counter/manager-counter.service';

declare const AOS: any;

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [
    RouterOutlet,
    RouterLink,
    ModalAccountCreateComponent,
    ButtonCloseOffcanvasComponent,
    SpinnerComponent
  ]
})
export class AppComponent implements OnInit {

  /******************************
   * Properties
   ******************************/

  readonly itemsMenu = [
    {
      path: '/followings',
      label: 'フォロー中',
      class: 'fa-solid fa-user-plus',
      roles: ['ADMIN', 'AGENT_PARENT', 'AGENT_CHILD', 'CREATOR', 'USER']
    },
    {
      path: '/chats',
      label: 'ギフチャ',
      class: 'fa-solid fa-comments',
      roles: ['ADMIN', 'AGENT_PARENT', 'AGENT_CHILD', 'CREATOR', 'USER']
    },
    {
      path: '/gifcomes',
      label: 'ギフコメ',
      class: 'fa-solid fa-comment-dots fa-flip-horizontal',
      roles: ['ADMIN', 'AGENT_PARENT', 'AGENT_CHILD', 'CREATOR', 'USER']
    },
    {
      path: '/wallet',
      label: 'ウォレット',
      class: 'fa-solid fa-wallet',
      roles: ['ADMIN', 'AGENT_PARENT', 'AGENT_CHILD', 'CREATOR', 'USER']
    },
    {
      path: '/profile',
      label: 'プロフィール',
      class: 'fa-solid fa-user',
      roles: ['ADMIN', 'AGENT_PARENT', 'AGENT_CHILD', 'CREATOR', 'USER']
    },
    {
      path: '/settings',
      label: '設定',
      class: 'fa-solid fa-cog',
      roles: ['ADMIN', 'AGENT_PARENT', 'AGENT_CHILD', 'CREATOR', 'USER']
    },
    {
      path: '/revenue',
      label: '収益',
      class: 'fa-solid fa-sack-dollar',
      roles: ['ADMIN', 'AGENT_PARENT', 'AGENT_CHILD', 'CREATOR']
    },
    {
      path: '/api',
      label: '外部サービス連携(API)',
      class: 'fa-solid fa-gear',
      roles: ['CREATOR']
    }
  ];

  get accountMine(): Account | undefined {
    return this.managerAccount.accountMine;
  }

  get configToast(): any {
    return this.utilNotification.configToast;
  }

  get unreads(): Gifcome[] | undefined {
    return this.managerChat.unreads;
  }

  /*** Status ***/

  get isAuthenticated(): boolean {
    return this.managerAuth.isAuthenticated;
  }

  get isProcessing(): boolean {
    return this.stateProcessing.isProcessing;
  }

  get isProcessingFirst(): boolean {
    return this.stateProcessing.isProcessing;
  }

  /******************************
   * Lifecyle hooks
   ******************************/

  constructor(
    private auth: Auth,
    private db: Firestore,
    private storage: Storage,
    private router: Router,
    private managerAccount: ManagerAccountService,
    private managerAuth: ManagerAuthService,
    private managerChat: ManagerChatService,
    private mgrCounter: ManagerCounterService,
    private stateProcessing: StateProcessingService,
    private utilNotification: UtilNotificationService
  ) {
    if (ENVIRONMENT.MODE === 'DEVELOPMENT') {
      connectAuthEmulator(this.auth, ENVIRONMENT.PARAMS.NETWORK.PROTOCOL + '://' + ENVIRONMENT.PARAMS.NETWORK.HOST_ADDRESS + ':9099');
      connectFirestoreEmulator(this.db, ENVIRONMENT.PARAMS.NETWORK.HOST_ADDRESS, 8080);
      connectStorageEmulator(this.storage, ENVIRONMENT.PARAMS.NETWORK.HOST_ADDRESS, 9199);
    }
  }

  async ngOnInit(): Promise<void> {
    const url: string = location.href;
    const indexAt = url.indexOf('@');
    if (indexAt !== -1) {
      const idUrlShortened = url.substring(indexAt + 1);
      const uid = await this.mgrCounter.getUidFromUrlShortened(idUrlShortened);

      if (!uid) {
        this.router.navigate(['/']);
      }
      else {
        this.router.navigate(['/', 'p', uid]);
      }
    }

    this.managerAccount.onboardAccountName();

    AOS.init();
  }

  /******************************
   * Methods
   ******************************/

  signIn(): void {
    this.managerAuth.signInWithRedirect();
  }

  /*** Status ***/

  isPageActive(path: string): boolean {
    return location.pathname === path;
  }
}
