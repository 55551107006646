<nav id="navbar-main" class="navbar border-top border-bottom bg-white">
  <div class="container-fluid align-items-center">
    <div role="button" routerLink="">
      <div class="d-flex align-items-center">
        <div>
          <img src="/assets/images/logo_symbol.svg" class="me-25" style="height: 2.0rem; margin-bottom: 2px;">
          <img src="/assets/images/logo_text.svg" alt="ギフコメ" style="height: 1.5rem;">
        </div>
        <small class="text-text-sub">®</small>
      </div>
    </div>

    @if(isAuthenticated) {
    <div>
      <button class="btn btn-link position-relative" type="button" data-bs-toggle="offcanvas" data-bs-target="#menu"
        aria-controls="menu">
        <i class="fa-solid fa-bars"></i>
        @if(unreads !== undefined && unreads.length > 0) {
        <span
          class="position-absolute top-0 right-0 bg-accent rounded-circle"
          style="padding: 0.25rem">
        </span>
        }
      </button>
    </div>
    }
    @else {
    <div>
      <button class="btn btn-link" type="button" (click)="signIn()">
        ログイン
      </button>
    </div>
    }
  </div>
</nav>


<main class="position-relative" id="main">
  <router-outlet />

  @if(isProcessing) {
  <section class="bg-white position-absolute left-0 top-0 h-100 w-100">
    <section class="py-3">
      <div class="container">
        <div class="content">

          <app-spinner></app-spinner>

        </div>
      </div>
    </section>
  </section>
  }
</main>

<div class="offcanvas offcanvas-end" tabindex="-1" id="menu">
  <div class="offcanvas-header border-bottom border-top justify-content-end">
    <app-button-close-offcanvas></app-button-close-offcanvas>
  </div>

  @if(accountMine !== undefined) {
  <div class="offcanvas-body">
    <ul class="list-group border-white">
      @for (itemMenu of itemsMenu; track $index) {
      @if(itemMenu.roles.includes(accountMine!.role)) {
      <a ariaCurrentWhenActive="page" class="list-group-item list-group-item-action border-white"
        [routerLink]="itemMenu.path" routerLinkActive="active-page" [class.active]="isPageActive(itemMenu.path)"
        data-bs-dismiss="offcanvas">
        <i [class]="itemMenu.class">
        </i>
        {{ itemMenu.label }}

        @if(itemMenu.path === '/chats' && unreads !== undefined && unreads.length > 0) {
        <span class="position-absolute top-0 right-0 badge rounded-pill bg-accent">
          <small>{{ unreads.length }}</small>
          <span class="visually-hidden">未読</span>
        </span>
        }
      </a>
      }
      }
      <a href="https://forms.gle/K4baZ4aUysdUGy4V9" class="list-group-item list-group-item-action border-white"
        target="_blank">
        <i class="fa-solid fa-envelope"></i>
        ご意見ご要望
      </a>
    </ul>
  </div>
  }
</div>

<app-modal-account-create></app-modal-account-create>

<div id="toast" class="toast position-fixed bottom-0 start-0 border-0 w-100" role="alert"
  style="z-index:100000; background-color: transparent; box-shadow: none"
  aria-atomic="true" aria-live="assertive">
  <div class="card card-body border-0 p-1" [class]="'bg-' + configToast.color" style="border-radius: 0;">
    <div class="text-white">
      <i [class]="configToast.icon"></i>{{ configToast.text }}
    </div>
  </div>
</div>