<section class="py-5">
    <div class="container">
        <div class="content row gy-3">

            <section>
                <div class="card">
                    <div class="card-body">
                        <div class="row gy-2">
                            <section>
                                <h5>プッシュ通知</h5>
                                <p>
                                    通知はアプリ/ブラウザ単位で設定されます。アカウント単位ではありません。またプライベートモードのブラウザでは、プッシュ通知は利用できません。
                                </p>

                            </section>

                            @if(isPushNotifSupported) {
                            <section>
                                <div>
                                    @if(!isPushNotifSubscribed) {
                                    <button class="btn btn-main rounded-pill" type="button"
                                        (click)="subscribePushNotification()">
                                        通知をオン
                                    </button>
                                    }
                                    @else {
                                    <button class="btn btn-main rounded-pill" type="button"
                                        (click)="unsubscribePushNotif()">
                                        通知を解除
                                    </button>
                                    }
                                </div>
                            </section>
                            }
                            @else {
                            <section>
                                <div>
                                    このアプリ/ブラウザではプッシュ通知が利用できません。
                                </div>
                            </section>
                            }

                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
</section>