<section id="navbar-chat" class="navbar bg-perl-1 border-bottom">
    <div class="container">
        <div class="content row w-100">
            <div>
                <div class="d-flex align-items-center justify-content-between">
                    <div>
                        <div class="d-flex align-items-center">
                            <a class="btn btn-link" role="button" [routerLink]="['/', 'chats']"
                                style="padding-left: 0.25rem">
                                <i class="fa-solid fa-angle-left"></i>
                            </a>

                            @if(accountTarget?.role !== 'CREATOR') {
                            <label>
                                {{ accountTarget?.name }}
                            </label>
                            }
                            @else {
                            <a role="button" [routerLink]="['/', 'p', accountTarget?.id]">
                                {{ accountTarget?.name }}
                            </a>
                            }

                            @if(isBot) {
                            <span class="badge bg-main rounded-pill ms-50" style="font-weight: normal">
                                AIモード
                            </span>
                            }
                        </div>
                    </div>

                    <div>
                        @if(isModeReport) {
                        <button class="btn btn-link btn-sm rounded-pill" type="button" (click)="endModeReport()">
                            キャンセル
                        </button>

                        <button class="btn btn-main btn-sm rounded-pill" type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#modalReportCreate">
                            通報内容を入力
                        </button>
                        }

                        <button class="btn btn-link btn-sm" type="button"
                            data-bs-toggle="dropdown" aria-expanded="false" style="padding-right: 0;">
                            <i class="fa-solid fa-ellipsis"></i>
                        </button>

                        <div class="dropdown">
                            <ul class="dropdown-menu dropdown-menu-end">
                                <li>
                                    <button class="dropdown-item" type="button" (click)="startModeReport()">
                                        <i class=" fa-solid fa-flag"></i>
                                        通報
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="chat-wrapper">
    <div id="chat-content" class="py-3" (scroll)="onScroll()">
        <div class="container">
            <div class="content row gy-2">

                @if(!isLoadable) {
                <section>
                    <div class="d-flex justify-content-center">
                        <p>
                            これ以上のギフコメはありません
                        </p>
                    </div>
                </section>
                }

                @if(isProcessing) {
                <section>
                    <app-spinner></app-spinner>
                </section>
                }

                <section>
                    <div class="row gy-50">
                        @for(gifcomes of gifcomess; track $index) {
                        @for(gifcome of gifcomes; track gifcome.id) {
                        @if(checkDateBorder(gifcome.id)) {
                        <section class="py-2">
                            <div class="d-flex justify-content-center">
                                <section class="text-white bg-text-link px-1"
                                    style="border-radius: 50rem; padding-bottom: 2px;">
                                    <small>{{ getLabelDate(gifcome.generatedAt) | date:
                                        getFormatDate(getLabelDate(gifcome.generatedAt)) }}</small>
                                </section>
                            </div>
                        </section>
                        }

                        <section>
                            <div class="d-flex align-items-end" [class.justify-content-end]="isMine(gifcome)">
                                @if(isMine(gifcome)) {
                                <div class="px-50 text-end" style="line-height: 1.0rem;">
                                    @if(getLabelRead(gifcome) === '既読') {
                                    <p>
                                        <small>
                                            <i class="fa-solid fa-check" style="margin-right: 0;"></i>
                                        </small>
                                    </p>
                                    }
                                    <p>
                                        <small>

                                            {{ getLabelTime(gifcome.generatedAt) }}
                                        </small>
                                    </p>
                                </div>
                                }

                                <div>
                                    <div class="card border-0 d-inline-block"
                                        style="max-width: 200px; border-radius: 0.5rem;"
                                        [style]="isMine(gifcome)? 'border-bottom-right-radius: 0;' : 'border-top-left-radius: 0'"
                                        [class.bg-accent]="isMine(gifcome)" [class.text-white]="isMine(gifcome)">

                                        @if(gifcome.urlAttachment || gifcome.urlImage) {
                                        @if(checkImage((gifcome.urlAttachment? gifcome.urlAttachment:
                                        gifcome.urlImage)!))
                                        {
                                        <img [src]="gifcome.urlAttachment? gifcome.urlAttachment: gifcome.urlImage"
                                            class="card-img-top"
                                            style="border-top-left-radius: 0.5rem; border-top-right-radius: 0.5rem;"
                                            role="button"
                                            (click)="showModalPreviewImage(gifcome.urlAttachment? gifcome.urlAttachment: gifcome.urlImage)">
                                        }
                                        @else {
                                        <video [src]="gifcome.urlAttachment? gifcome.urlAttachment: gifcome.urlImage"
                                            class="card-img-top" controls
                                            style="border-top-left-radius: 0.5rem; border-top-right-radius: 0.5rem;">
                                        </video>
                                        }
                                        }

                                        <div class="card-body px-1 py-50">
                                            @if(getPlanGifcome(gifcome.idPlanGifcome).point > 0) {
                                            <img [src]="getPlanGifcome(gifcome.idPlanGifcome).urlIcon" class="me-0"
                                                style="width: 1.0rem; margin-bottom: 3px;">
                                            }
                                            {{ gifcome.comment }}

                                            @if(checkTranslating(gifcome.id)) {
                                            <br>
                                            <div class="spinner-border spinner-border-sm text-main" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                            }
                                            @else {
                                            @if(!isMine(gifcome) && !checkJapanese(gifcome.comment)) {
                                            @if(gifcome.commentTranslated) {
                                            <p>
                                                <small>翻訳: {{ gifcome.commentTranslated }}</small>
                                            </p>
                                            }
                                            @else {
                                            <br>
                                            <button class="btn btn-link btn-sm" style="padding: 0" type="button"
                                                (click)="translate(gifcome)">
                                                翻訳
                                            </button>
                                            }
                                            }
                                            }
                                        </div>
                                    </div>
                                </div>

                                @if(!isMine(gifcome)) {
                                <div class="px-50">
                                    @if(!isModeReport) {
                                    <p><small>{{ getLabelTime(gifcome.generatedAt) }}</small></p>
                                    }
                                    @else {
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox"
                                            (click)="checkGifcome($event, gifcome.id)">
                                    </div>
                                    }
                                </div>
                                }
                            </div>
                        </section>
                        }
                        }
                    </div>
                </section>

                @if(hasFirstGifcome) {
                <section>
                    <div class="d-flex justify-content-end">
                        <app-modal-gifcome-create [accountTarget]="accountTarget" [uidFor]="uidCreator"
                            [isGifcomePrivate]="true"></app-modal-gifcome-create>
                        <button class="btn btn-main btn-lg rounded-pill" data-bs-toggle="modal"
                            data-bs-target="#modalGifcomeCreate">
                            <i class="fa-solid fa-gift"></i>
                            ギフコメで返信
                        </button>
                    </div>
                </section>
                }

                @if((isPushNotifSupported && !isPushNotifSubscribed) && !isExtension()) {
                <section>
                    <div class="d-flex justify-content-end">
                        <button class="btn btn-outline-main rounded-pill" type="button"
                            (click)="subscribePushNotification()">
                            このアプリ/ブラウザで通知をオン
                        </button>
                    </div>
                </section>
                }
            </div>
        </div>
    </div>
</section>

<app-modal-preview-image [urlImage]="urlImage"></app-modal-preview-image>
<app-modal-report [uidTarget]="uidTarget" [idsGifcome]="idsGifcome"></app-modal-report>