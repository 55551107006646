import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { ENVIRONMENT } from '../../../../../../../settings/environments/environment.development';
import { Firestore, addDoc, collection, deleteDoc, doc, getDocs, query, where } from '@angular/fire/firestore';
import { ManagerUserService } from '../../managers/manager-user/manager-user.service';
import { UtilNotificationService } from '../../utils/util-notification/util-notification.service'


@Injectable({
  providedIn: 'root'
})
export class ManagerPushNotifService {

  get isSupported(): boolean {
    // Notification APIがサポートされているか確認
    const isNotificationSupported = 'Notification' in window;
    // Service Workerがサポートされているか確認
    const isServiceWorkerSupported = 'serviceWorker' in navigator;
    // プライベートブラウザでないことを確認
    const isNotPrivateBrowser = !this.isIncognitoMode;

    return isNotificationSupported && isServiceWorkerSupported && isNotPrivateBrowser;
  }

  get isIncognitoMode(): boolean {
    return false;
  }

  private _subscToThisApp: PushSubscription | null = null;
  get subscToThisApp(): PushSubscription | null {
    return this._subscToThisApp;
  }

  constructor(
    private push: SwPush,

    private db: Firestore,

    private mgrUser: ManagerUserService,

    private utilNotification: UtilNotificationService
  ) {
    this.push.subscription.subscribe(subsc => {
      this._subscToThisApp = subsc;
      console.log(subsc);
    });
  }

  async gets(): Promise<any[]> {
    const ref = collection(this.db, 'subscs_push_notif');
    const q = query(ref, where('uid', '==', this.mgrUser.user!.uid));
    const snapshot = await getDocs(q);
    const subscs = snapshot.docs.map(doc => {
      return {
        id: doc.id,
        ...doc.data()
      }
    });

    console.log(subscs);

    return subscs;
  }

  async subscribe(): Promise<void> {
    const obj = await this.push.requestSubscription({
      serverPublicKey: ENVIRONMENT.PARAMS.FUNCTIONS.PUSH_NOTIF.VAPID_KEY_PUBLIC
    });
    if (!obj) {
      throw new Error('Failed to subscribe to push notifications.');
    }

    const data = JSON.parse(JSON.stringify(obj));
    const ref = collection(this.db, 'subscs_push_notif');
    const response = addDoc(ref, {
      uid: this.mgrUser.user!.uid,
      data: data
    });

    response.then(() => {
      this.utilNotification.notify('プッシュ通知がオンになりました。', 'SUCCESS');
    }).catch(() => {
      this.utilNotification.notify('プッシュ通知のオンに失敗しました。', 'ERROR');
    });
  }

  async unsubscribe(subscs: any[]): Promise<void> {
    const endpoint = this.subscToThisApp!.endpoint;
    console.log(endpoint);
    console.log(subscs);
    const subsc = subscs.find(subsc => subsc.data.endpoint === endpoint);
    console.log(subsc);

    if (this.subscToThisApp === null || subsc === undefined) {
      return;
    }

    return this.push.unsubscribe().then(() => {
      const ref = doc(this.db, 'subscs_push_notif', subsc.id);
      const response = deleteDoc(ref);

      return response;
    }).then(() => {
      this.utilNotification.notify('プッシュ通知がオフになりました。', 'SUCCESS');
    }).catch(() => {
      this.utilNotification.notify('プッシュ通知のオフに失敗しました。', 'ERROR');
    });
  }
}
